import React from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BsFillPlusSquareFill } from "react-icons/bs";
import * as yup from "yup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { BASE_URL } from "../../config/index";
const options = [
  { value: "นาย", label: "นาย" },
  { value: "นาง", label: "นาง" },
  { value: "นางสาว", label: "นางสาว" },
];

const schema = yup.object().shape({
  firstName: yup.string().required("กรุณาใส่ firstName"),
  lastName: yup.string().required("กรุณาใส่ lastName"),
  addressDetail: yup.string().required("กรุณาใส่ addressDetail"),
  tel: yup.string().required("กรุณาใส่เบอร์โทรศัพท์"),
  email: yup.string().email(),
  emergencyContactName: yup.string(),
  emergencyContactTel: yup.string(),
  zipCode: yup
    .string()
    .length(5)
    .matches(/^[0-9]{5}/)
    .required(),
});
const api = axios.create({
  baseURL: `${BASE_URL}/`,
});
const CustomerCreatePage = () => {
  const MySwal = withReactContent(Swal);
  const [isSubdistrictDisabled, setIsSubdistrictDisabled] =
    React.useState(true);
  const [address, setAddress] = React.useState([]);
  const [addressMaster, setAddressMaster] = React.useState([]);
  const [nationalDocument, setNationalDocument] = React.useState(null);

  const [district, setDistrict] = React.useState(null);
  const [province, setProvince] = React.useState(null);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [prefix, setPrefix] = React.useState(null);
  const [error, setError] = React.useState(null);
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const formData = new FormData();
      formData.append("nationalDocument", nationalDocument);
      formData.append("firstName", data.firstName);
      formData.append("lastName", data.lastName);
      formData.append("tel", data.tel);
      formData.append("emergencyContactName", data.emergencyContactName);
      formData.append("email", data.email);
      formData.append("emergencyContactTel", data.emergencyContactTel);
      formData.append("addressDetail", data.addressDetail);
      formData.append("addressMaster", selectedCustomer);
      formData.append("preFix", prefix);
      await api.post(`customer/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      MySwal.fire({
        icon: "success",
        title: "Create propertyAsset Success",
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        history.replace("/customer");
      });
    } catch (error) {
      MySwal.fire({
        icon: "error",
        title: error?.response?.data?.error?.message,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const getMasterAddress = async (zipCode) => {
    try {
      setIsSubdistrictDisabled(true);
      if (!profileValue) {
        history.replace("/login");
      }
      const urlPath = `/address/searchByPostalCode/${zipCode}`;
      const resp = await api.get(urlPath, {
        cancelToken: cancelToken.current.token,
        headers: {
          Authorization: "Bearer " + profileValue.access_token,
        },
      });
      setAddress(resp.data.format);
      setAddressMaster(resp.data.data);
      setIsSubdistrictDisabled(false);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setIsSubdistrictDisabled(false);
    }
  };
  const callAddressFromZipCode = (event) => {
    let value = event.target.value;
    if (value.length < 5) {
      return 0;
    }
    getMasterAddress(value);
  };
  const handleChangeSubdistrict = (selectedOption) => {
    const temp = addressMaster.find(
      (item) => item.uid === selectedOption.value
    );
    setSelectedCustomer(selectedOption.value);
    setDistrict(temp.districtNameTh);
    setProvince(temp.provinceNameTh);
  };

  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    // getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <Button variant="dark" onClick={() => history.push("/customer/")}>
              Back to Customer
            </Button>{" "}
          </Col>
        </Row>
      </Container>
      <Container className="mt-4">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridPrefix">
                <Form.Label>Prefix</Form.Label>
                <Select
                  options={options}
                  onChange={(selectedOption) => {
                    setPrefix(selectedOption.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridFirstName">
                <Form.Label>firstName</Form.Label>
                <Form.Control
                  placeholder="firstName"
                  type="string"
                  name="firstName"
                  ref={register}
                  className={`form-control ${
                    errors.firstName ? "is-invalid" : ""
                  }`}
                />
                {errors.firstName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.firstName.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>lastName</Form.Label>
                <Form.Control
                  placeholder="lastName"
                  type="string"
                  name="lastName"
                  ref={register}
                  className={`form-control ${
                    errors.lastName ? "is-invalid" : ""
                  }`}
                />
                {errors.lastName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.lastName.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridTel">
                <Form.Label>Telephone</Form.Label>
                <Form.Control
                  placeholder="Telephone"
                  type="string"
                  name="tel"
                  ref={register}
                  className={`form-control ${errors.tel ? "is-invalid" : ""}`}
                />
                {errors.tel && (
                  <Form.Control.Feedback type="invalid">
                    {errors.tel.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>email</Form.Label>
                <Form.Control
                  placeholder="email"
                  type="email"
                  name="email"
                  ref={register}
                  className={`form-control ${errors.email ? "is-invalid" : ""}`}
                />
                {errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridEmergencyContactName">
                <Form.Label>EmergencyContact Name</Form.Label>
                <Form.Control
                  placeholder="EmergencyContact Name"
                  type="string"
                  name="emergencyContactName"
                  ref={register}
                  className={`form-control ${
                    errors.emergencyContactName ? "is-invalid" : ""
                  }`}
                />
                {errors.emergencyContactName && (
                  <Form.Control.Feedback type="invalid">
                    {errors.emergencyContactName.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridEmergencyContactTel">
                <Form.Label>EmergencyContact Tel</Form.Label>
                <Form.Control
                  placeholder="EmergencyContact Tel"
                  type="string"
                  name="emergencyContactTel"
                  ref={register}
                  className={`form-control ${
                    errors.emergencyContactTel ? "is-invalid" : ""
                  }`}
                />
                {errors.emergencyContactTel && (
                  <Form.Control.Feedback type="invalid">
                    {errors.emergencyContactTel.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridZipCode">
                <Form.Label>ZIP Code</Form.Label>
                <Form.Control
                  placeholder="zipCode"
                  type="text"
                  name="zipCode"
                  ref={register}
                  // value={principle}
                  onChange={callAddressFromZipCode}
                  className={`form-control ${
                    errors.zipCode ? "is-invalid" : ""
                  }`}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridCusoffDate">
                <Form.Label>ตำบล</Form.Label>
                <Select
                  options={address}
                  isSearchable={true}
                  isDisabled={isSubdistrictDisabled}
                  onChange={handleChangeSubdistrict}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group as={Col} controlId="formGridAddressDetail">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  placeholder="Address Detail"
                  type="string"
                  name="addressDetail"
                  ref={register}
                  className={`form-control ${
                    errors.addressDetail ? "is-invalid" : ""
                  }`}
                />
                {errors.addressDetail && (
                  <Form.Control.Feedback type="invalid">
                    {errors.addressDetail.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridDistrict">
                <Form.Label>อำเภอ</Form.Label>
                <Form.Control
                  placeholder="อำเภอ"
                  type="text"
                  name="district"
                  ref={register}
                  disabled={true}
                  value={district}
                />
                {errors.district && (
                  <Form.Control.Feedback type="invalid">
                    {errors.district.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Col} controlId="formGridProvince">
                <Form.Label>จังหวัด</Form.Label>
                <Form.Control
                  placeholder="จังหวัด"
                  type="text"
                  name="province"
                  ref={register}
                  disabled={true}
                  value={province}
                />
                {errors.province && (
                  <Form.Control.Feedback type="invalid">
                    {errors.province.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row></Row>

          <Row>
            <Col>
              <Form.Group as={Col}>
                <Form.File
                  className="position-relative"
                  name="file"
                  label="สำเนาบัตรประชาชน"
                  required
                  onChange={(e) => setNationalDocument(e.target.files[0])}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Button variant="primary" className="mt-3" type="submit">
                <BsFillPlusSquareFill className="mr-2" />
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </>
  );
};

export default CustomerCreatePage;
