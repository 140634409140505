import React from "react";
import {
  Container,
  Row,
  Col,
  Spinner,
  Button,
  Form,
  Alert,
} from "react-bootstrap";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import { BASE_URL } from "../../config/index";
const apiBase = axios.create({
  baseURL: `${BASE_URL}`,
});
const PropertyCrateTransactionPage = () => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [isCreated, setIsCreated] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [afterPay, setAfterPay] = React.useState(null);
  const [notification, setNotification] = React.useState(null);
  const cancelToken = React.useRef(null);
  const profileValue = JSON.parse(localStorage.getItem("token"));
  const history = useHistory();
  const getData = async () => {
    try {
      if (!profileValue) {
        history.replace("/login");
      }
      setLoading(true);
    } catch (err) {
      if (err.response?.status === 401) {
        history.replace("/login");
      }
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  const createNotification = async (event) => {
    try {
      const resp = await apiBase.post(
        `/notification/property`,
        {
          propertId: id,
        },
        {
          cancelToken: cancelToken.current.token,
          headers: {
            Authorization: "Bearer " + profileValue.access_token,
          },
        }
      );
      setNotification(resp.data);
      setAfterPay(resp.data.afterPay);
    } catch (err) {
      console.log(err.message);
    } finally {
      setIsCreated(true);
    }
  };
  const calculateAfterPay = () => {
    const interate = document.getElementById("interest").value;
    const payment = document.getElementById("payment").value;
    const principle = notification.principle;
    let newAfterPay = (
      parseFloat(principle) +
      parseFloat(interate) -
      parseFloat(payment)
    ).toFixed(2);
    const [first, second] = newAfterPay.split(".");
    newAfterPay = first
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setAfterPay(`${newAfterPay}.${second}`);
  };
  const confirmPayment = async () => {
    try {
      const interest = document.getElementById("interest").value;
      const payment = document.getElementById("payment").value;
      await apiBase.put(
        `/notification/property/${notification.notificationUid}`,
        {
          payment: parseFloat(payment),
          interest: parseFloat(interest),
        },
        {
          cancelToken: cancelToken.current.token,
          headers: {
            Authorization: "Bearer " + profileValue.access_token,
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      history.push("/propertyAsset/");
    }
  };
  React.useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getData();
    return () => {
      cancelToken.current.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading === true) {
    return (
      <div className="text-center mt-5">
        <Spinner animation="grow" variant="info" />
      </div>
    );
  }
  if (error) {
    return (
      <div className="text-center mt-5">
        <p>Try Again</p>
        <p>{error}</p>
      </div>
    );
  }
  return (
    <>
      <Container className="mt-4">
        <Row>
          <Col>
            <Button
              variant="dark"
              onClick={() => history.push("/propertyAsset/")}
            >
              Back to propertyAsset
            </Button>{" "}
          </Col>
          <Col>
            <Button
              variant="success"
              className="text-center"
              disabled={isCreated}
              onClick={() => {
                createNotification("");
              }}
            >
              Create Payment
            </Button>
          </Col>
        </Row>
        <>
          {notification && (
            <Form>
              {" "}
              <Row>
                <Col>
                  <Form.Label>ยอดเงินทั้งหมด</Form.Label>
                  <Form.Control
                    type="text"
                    name="principle"
                    disabled={true}
                    defaultValue={notification?.totalLoan}
                  />
                </Col>
                <Col>
                  <Form.Label>ยอดคงเหลือ</Form.Label>
                  <Form.Control
                    type="text"
                    name="principle"
                    disabled={true}
                    defaultValue={notification?.principle}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>จำนวนงวดทั้งหมด</Form.Label>
                  <Form.Control
                    type="text"
                    name="principle"
                    disabled={true}
                    defaultValue={notification?.periodTotal}
                  />
                </Col>
                <Col>
                  <Form.Label>จำนวนงวดคงเหลือ</Form.Label>
                  <Form.Control
                    type="text"
                    name="principle"
                    disabled={true}
                    defaultValue={notification?.period}
                  />
                </Col>
              </Row>
              <Row>
                <Form.Label>payment</Form.Label>
                <Form.Control
                  type="text"
                  name="payment"
                  id="payment"
                  onKeyDown={calculateAfterPay}
                  defaultValue={notification?.payment}
                />
              </Row>
              <Row>
                <Form.Label>interest</Form.Label>
                <Form.Control
                  type="text"
                  name="interest"
                  id="interest"
                  onKeyDown={calculateAfterPay}
                  defaultValue={notification?.interate}
                />
              </Row>
              <Row>
                <Alert>After Pay : {afterPay}</Alert>
              </Row>
              <Row>
                <Button
                  variant="danger"
                  className="mt-2"
                  onClick={() => {
                    confirmPayment();
                  }}
                >
                  Confirm Payment
                </Button>
              </Row>
            </Form>
          )}
        </>
      </Container>
    </>
  );
};

export default PropertyCrateTransactionPage;
